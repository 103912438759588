<template>
    <div class="updateArticle">
        <mavon-editor
            class="md-con"
            ref="md"
            v-model="mark_content"
            :externalLink="externalLink"
            :subfield="subfield"
            :code_style="code_style"
            :ishljs="true"
            @save="saveArticle"
            @imgAdd="$imgAdd"
            @imgDel="$imgDel"
        />
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    export default {
        name: 'updateArticle',

        data() {
            return {
                mark_content: '',
                subfield: true,
                code_style: 'solarized-dark',
                articleId: this.$route.query.articleId,
                externalLink: {
                    markdown_css: function () {
                        // 这是你的markdown css文件路径
                        return '/markdown/github-markdown.min.css';
                    },
                    hljs_js: function () {
                        // 这是你的hljs文件路径
                        return '/highlightjs/highlight.min.js';
                    },
                    hljs_css: function (css) {
                        // 这是你的代码高亮配色文件路径
                        return '/highlightjs/styles/' + css + '.min.css';
                    },
                    hljs_lang: function (lang) {
                        // 这是你的代码高亮语言解析路径
                        return '/highlightjs/languages/' + lang + '.min.js';
                    },
                    katex_css: function () {
                        // 这是你的katex配色方案路径路径
                        return '/katex/katex.min.css';
                    },
                    katex_js: function () {
                        // 这是你的katex.js路径
                        return '/katex/katex.min.js';
                    },
                }
            };
        },

        mounted() {
            this.getOneArticle()
            console.log(this.$route.query.articleId);
        },

        methods: {
            ...mapActions({vxChangeArticleMD: 'root/changeArticleMD', vxDeleteArticleImg: 'root/deleteArticleImg', vxGetOneArticle: 'root/getOneArticle'}),
            saveArticle(value) {
                console.log(value);
                this.vxChangeArticleMD({id: this.$route.query.articleId, mark_content: value})
            },
            $imgAdd(pos, $file) {
                // 第一步.将图片上传到服务器.
                var formdata = new FormData();
                formdata.append('image', $file);
                this.$axios({
                    url: '/root/articleImg',
                    method: 'post',
                    data: formdata,
                    headers: {'Content-Type': 'multipart/form-data'},
                }).then((url) => {
                    // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
                    /**
                    * $vm 指为mavonEditor实例，可以通过如下两种方式获取
                    * 1. 通过引入对象获取: `import {mavonEditor} from ...` 等方式引入后，`$vm`为`mavonEditor`
                    * 2. 通过$refs获取: html声明ref : `<mavon-editor ref=md ></mavon-editor>，`$vm`为 `this.$refs.md`
                    */
                    console.log(url.data.url);
                    let imgUrl = url.data.url
                    this.$refs.md.$img2Url(pos, imgUrl);
                })
            },
            $imgDel(pos, $file) {
                // api/articleImg/1647247760035image.png 后台返回的链接是这样的，需要把api去掉
                // delete this.img_file[pos];/
                this.vxDeleteArticleImg({imgUrl: pos[0].substring(3)})
            },
            async getOneArticle() {
                const {data} = await this.vxGetOneArticle({articleId: this.articleId})
                console.log(data);
                // this.articleInfo = data
                this.mark_content = data.mark_content
            }
        },
    };
</script>

<style lang="less" scoped>
    .updateArticle {
        max-width: 1754px;
    }
    // .md-con {
    //     max-width: 70%;
    // }
</style>